import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  annotationPlugin
);

const BasketballStatsGraph = ({ data }) => {
  // Extracting dates and points from the data
  const [stat, setStat] = useState("pts");
  const dates = data.map((game) => new Date(game.game.date).toLocaleDateString());
  const points = data.map((game) => game[stat]);
  const [line, setLine] = useState([]);

  const chartData = {
    labels: dates,
    datasets: [
      {
        label: 'Points',
        data: points,
        borderColor: '#BB86FC',
        backgroundColor: '#121212'
      }
    ],
  };

  const options = {
    plugins: {
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y',
            value: line,
            borderColor: 'red', // Color of the horizontal line
            borderWidth: 2,
            label: {
              content: 'Line',
              enabled: true,
              position: 'left', // Adjust position as needed
            },
          },
        ],
      },
    },
  };

  return (
    <div style={{ marginLeft: '5px', marginRight: '10px' }}>
      <h2 style={{ color: "white" }}>Points This Season</h2>
      <Line style={{ maxWidth:600, maxHeight:400 }} data={chartData} options={options} />
      <div style={{ display: 'flex', marginBottom: '50px' }}>
        <div style={{ display: 'flex', alignItems: 'center' , margin: 'auto'}}>
          <input
            style={{ height:38, width:75, textAlign:'center', marginRight: '10px', color: 'white', backgroundColor: '#212529', border: '1px solid #393945', borderRadius: '5px' }}
            type="text"
            placeholder="Line"
            value={line}
            onChange={e => setLine(e.target.value)}
          />
          <DropdownButton stlye={{ height: '35px' }} id="dropdown-basic-button" title={ stat === 'turnover' ? 'TOV' : stat.toUpperCase() }>
            <Dropdown.Item onClick={() => setStat("pts")}>PTS</Dropdown.Item>
            <Dropdown.Item onClick={() => setStat("ast")}>AST</Dropdown.Item>
            <Dropdown.Item onClick={() => setStat("reb")}>REB</Dropdown.Item>
            <Dropdown.Item onClick={() => setStat("stl")}>STL</Dropdown.Item>
            <Dropdown.Item onClick={() => setStat("blk")}>BLK</Dropdown.Item>
            <Dropdown.Item onClick={() => setStat("turnover")}>TOV</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default BasketballStatsGraph;
