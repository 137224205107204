import "./App.css";
import './index.css';
import BasketballStatsGraph from "./BasketballStatsGraph";
import BasketballStatsTable from './BasketballStatsTable';
import React, { useState, useEffect } from "react";

function App() {
  const [player, setPlayer] = useState('');
  const [playerName, setPlayerName] = useState('');
  const statsUrl = "https://www.balldontlie.io/api/v1/stats?seasons[]=2023&player_ids[]=";
  const playerUrl = "https://www.balldontlie.io/api/v1/players?search=";
  const [data, setData] = useState([]);

  const fetchStatsInfo = (playerId) => {
    return fetch(statsUrl + playerId)
      .then((res) => res.json())
      .then((d) => setData(d.data))
  }

  const fetchPlayerInfo = () => {
    fetch(playerUrl + player)
      .then((res) => res.json())
      .then((d) => {
        if (!d.data[0]?.id) {
          alert("Player could not be found.")
        }
        else {
          setPlayerName(d.data[0].first_name + ' ' + d.data[0].last_name)
          fetchStatsInfo(d.data[0].id)
        }
      })
  }

  const handleChange = (event) => {
    setPlayer(event.target.value);
  }

  const handleSubmit = () => {
    fetchPlayerInfo();
  }



  useEffect(() => {
    fetchStatsInfo('');
  }, []);

  return (
    <div className="App">
      <h1 style={{ fontSize: '50px', height: '25%', color: '#03DAC6', fontFamily: 'MyFont', marginBottom: '20px' }}>WAGER WIZARDS</h1>
      <center>
        <div style={{ maxHeight: '28px' }}>
          <input style={{ color: 'white', backgroundColor: '#212529', border: '1px solid #393945', borderRadius: '5px 0 0 5px', minHeight: '100%' }} type="text" value={player} placeholder="Search player..." onChange={handleChange}/>
          <input style={{ color: 'white', backgroundColor: '#212529', border: '1px solid #393945', borderRadius: '0 5px 5px 0', minHeight: '100%' }} type="button" value="Search"onClick={handleSubmit} />
        </div>
        <br/>
        <h4 style={{ marginTop: '5px', marginBottom: '5px', color: "#CF6679" }}>{playerName}</h4>
        <BasketballStatsGraph data={data}/>
        <BasketballStatsTable data={data}/>
      </center>
      <h1></h1>
    </div>
  );
}

export default App;
