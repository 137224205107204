import React from 'react';
import Table from 'react-bootstrap/Table';

const calculateAverages = (data) => {
  const averages = {
    pts: 0,
    ast: 0,
    reb: 0,
    stl: 0,
    blk: 0,
    turnover: 0,
  };

  data.forEach((game) => {
    averages.pts += game.pts;
    averages.ast += game.ast;
    averages.reb += game.reb;
    averages.stl += game.stl;
    averages.blk += game.blk;
    averages.turnover += game.turnover;
  });

  const totalGames = data.length;

  // Calculate averages
  for (const key in averages) {
    averages[key] = (averages[key] / totalGames).toFixed(2);
  }

  return averages;
};

const BasketballStatsTable = ({ data }) => {
  // Check if "data" is an array
  if (!Array.isArray(data)) {
    return <div>No data available</div>;
  }

  const averages = calculateAverages(data);

  return (
    <div style={{ marginLeft: '5px', marginRight: '10px' }}>
      <h2 style={{ color: "white" }}>Stats This Season</h2>
      <Table style={{ maxWidth: "600px" }} striped="columns" variant="dark" responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Points</th>
            <th>Assists</th>
            <th>Rebounds</th>
            <th>Steals</th>
            <th>Blocks</th>
            <th>Turnovers</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Averages</td>
            <td>{averages.pts}</td>
            <td>{averages.ast}</td>
            <td>{averages.reb}</td>
            <td>{averages.stl}</td>
            <td>{averages.blk}</td>
            <td>{averages.turnover}</td>
          </tr>
          {data.map((game) => (
            <tr key={game.id}>
              <td>{new Date(game.game.date).toLocaleDateString()}</td>
              <td>{game.pts}</td>
              <td>{game.ast}</td>
              <td>{game.reb}</td>
              <td>{game.stl}</td>
              <td>{game.blk}</td>
              <td>{game.turnover}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BasketballStatsTable;
